<template>
    <landing :breadCrumbs="breadCrumbs" :shopName="shopName">
        <v-container>
            <v-row
                id="description"
                justify="center"
                :class="smAndUp ? 'mb-15' : null"
            >
                <v-col cols="auto" align="center">
                    <heading
                        ><span class="red--text">南</span>行徳駅前店</heading
                    >
                </v-col>
            </v-row>

            <!-- 紹介文 -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">アクセス</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >南行徳駅徒歩30秒!</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">住所</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >千葉県市川市南行徳1丁目16-1</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">営業時間</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平日13:00～22:00/土日10:00～18:00<br />※時間外は応相談</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">定休日</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >なし</span
                        >
                    </div>
                </v-col>
            </v-row>

            <!-- 代表イメージ -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img height="360px" :src="imageMv"></v-img>
                    </v-card>
                </v-col>
                <v-col v-if="!smAndUp" cols="12" align="center">
                    <v-icon
                        small
                        color="red darken-2"
                        @click="$vuetify.goTo('#target')"
                        >fas fa-chevron-down</v-icon
                    >
                    駅からのご案内
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="bottom center"
                            height="360px"
                            :src="shopImage"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 360px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1617667698525!6m8!1m7!1sS-sKq9TDcedDCjnEaAs1vw!2m2!1d35.67266787611496!2d139.9024652180222!3f117.84859049826079!4f4.049886828925622!5f0.7820865974627469"
                            width="100%"
                            height="360px"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                        ></iframe>
                    </v-card>
                </v-col>
            </v-row>

            <!-- 店舗個別メッセージ -->
            <shop-message :message="this.message" :shopImage="trainerImage" />

            <!-- ナビゲーション -->
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <heading
                        ><span id="target" class="red--text">南行徳駅</span
                        >からお越しの方</heading
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">1</span>.
                            改札を出ましたら、左の南口の階段を降りてください。
                        </div></v-card-title
                    >
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessA"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">2</span>.
                            階段を降りると、目の前にパチンコ屋と居酒屋とり鉄がございますので、線路に沿って左に曲がります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessB"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">3</span>.
                            線路沿いに進むと右手に山下書店さん、京成不動産さんの入った建物があります。そちらの2階にREVOISTがございます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessC"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">4</span>.
                            右側の外階段を登っていただき、一番手前（右側）の店舗になります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessD"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </landing>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import ShopMessage from '@/components/landing/ShopMessage.vue'
import Landing from '@/views/Landing.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document.querySelector('meta[name="description"]').setAttribute(
            'content',
            `パーソナルジム REVOISTの3号店です！
南行徳駅南口から徒歩30秒の駅近のジムです！
雨の日の電車で通勤帰りでも、駅から濡れることなく、パーソナルトレーニングに通うことができます！
南行徳にお住まいの多くのお客様に通っていただいております！
プロのパーソナルトレーナーがトレーニングや食事のアドバイスはもちろん、コミュニケーションも大切にしながら、あなたに合った楽しいレッスンを提供します！`
        )
    },
    components: {
        Heading,
        FooterContents,
        ShopMessage,
        Landing,
    },
    data() {
        return {
            shopName: '南行徳駅前店',
            center: { lat: 35.672840056738046, lng: 139.9026173689448 },
            zoom: 18,
            markers: [
                {
                    position: {
                        lat: 35.672840056738046,
                        lng: 139.9026173689448,
                    },
                    title: '南行徳駅前店',
                },
            ],
            message: `パーソナルジム REVOISTの3号店です！<br />
南行徳駅南口から徒歩30秒の駅近のジムです！<br />
雨の日の電車で通勤帰りでも、駅から濡れることなく、パーソナルトレーニングに通うことができます！<br />
南行徳にお住まいの多くのお客様に通っていただいております！<br />
プロのパーソナルトレーナーがトレーニングや食事のアドバイスはもちろん、コミュニケーションも大切にしながら、あなたに合った楽しいレッスンを提供します！<br /><br />

理想の生活、お身体に添った食事指導や運動指導でサポートさせていただきます。 健康的で充実したライフスタイ ルを一緒に作りましょう！<br />
南行徳駅前店トレーナー：岩本大輝`,
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '店舗一覧・アクセス',
                    disabled: false,
                    href: 'gyms',
                },
                {
                    text: '南行徳駅前店',
                    disabled: true,
                    href: 'access-4',
                },
            ]
        },

        imageMv() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_3/IMG_3182-1@2x.png'
                : 'static/sp_access_3/IMG_3182-1@2x.png'
        },
        accessA() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_3/IMG_3185.png'
                : 'static/sp_access_3/IMG_3185.png'
        },
        accessB() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_3/IMG_3189.png'
                : 'static/sp_access_3/IMG_3189.png'
        },
        accessC() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_3/IMG_3192.png'
                : 'static/sp_access_3/IMG_3192.png'
        },
        accessD() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_3/IMG_3182.png'
                : 'static/sp_access_3/IMG_3182.png'
        },

        shopImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/MinamiGyotokuSocola.jpg'
                : 'static/landing/MinamiGyotokuSocola.jpg'
        },
        trainerImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/trainer/南行徳駅前店　岩本大輝トレーナー.jpg'
                : 'static/landing/trainer/南行徳駅前店　岩本大輝トレーナー.jpg'
        },
    },
}
</script>

<style lang="scss">
.navigation {
    min-height: 3em;
}
</style>
